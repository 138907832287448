import "./App.css";

function App() {
  return (
    <div id="tele">
      █ █ █ <span style={{ color: "black" }}>█ █ █ █ █ █ █ █ █ █ </span>31%
      <br />
      &gt; Connecting images &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;
      <br />
      &gt; connecting care <span id="test">█</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
  );
}

export default App;
